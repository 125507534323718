.week {
    /* display: flex; */
}

.day {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dayHeader {
    text-align: center;
    padding: 0.5rem 0;
    width: 100%;
    border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

.dayName {
    font-size: 0.9rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    line-height: 1.4rem;
    margin-bottom: 0.25rem;
}

.dayDate {
    font-size: 0.9rem;
    font-family: var(--segoe);
    color: #929ea7;
    line-height: 1.4rem;
    margin-bottom: 0.25rem;
}

.dayHalf {
    min-height: 8rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    flex: 1;
}

.dayHeaders,
.dayHalves {
    display: flex;
}

.dayHalves {
    position: relative;
}

.dayHeaders > div:nth-child(even),
.dayHalves > div:nth-child(even) {
    background-color: #eee;
}

.dayHalves:nth-child(2) {
    border-bottom: 1px solid rgb(203, 203, 203);
}

.jumpButton {
    background-color: var(--green) !important;
    color: #fff;
    font-family: var(--segoe);
    font-size: 1rem;
    /* line-height: 1.6rem; */
    padding: 0 2.5rem;
    position: absolute;
    border-radius: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    top: -1.5rem;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.1s ease;
    white-space: nowrap;
}

.jumpButton:hover {
    cursor: pointer;
    transform: translateX(-50%) scale(1.01);
}

.dayHalves .chevron {
    width: 2rem;
    height: 2rem;
    /* margin-top: 0.3rem; */
    margin-left: 1rem;
}

@media screen and (max-width: 599px) {
    .dayDate {
        color: var(--darkBlue);
        font-size: 0.8rem;
        line-height: 1.3rem;
    }

    .dayName {
        font-size: 0.8rem;
        line-height: 1.3rem;
    }

    .jumpButton {
        font-size: 0.8rem;
        padding: 0;
        height: 2.5rem;
    }

    .jumpText {
        padding-left: 1rem;
    }
}
