.button {
    background-color: var(--green);
    color: #fff;
    font-family: var(--segoe);
    font-size: 0.9rem;
    line-height: normal;
    width: 7rem;
    max-width: 90%;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.6rem;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
    transition: all 0.1s ease;
}

.hidden {
    display: none;
}

.button:hover {
    cursor: pointer;
    transform: scale(1.05);
}

@media screen and (max-width: 599px) {
    .button {
        font-size: 0.7rem;
        line-height: normal;
        width: 7rem;
        max-width: 90%;
        height: 1.8rem;
        border-radius: 1.6rem;
    }
}
