.title {
    cursor: pointer;
    color: #1fba9c;
}

.caseDataTable thead tr th:nth-child(n + 1):nth-child(-n + 12) {
    display: none;
}

.caseDataTable tbody tr td:nth-child(n + 1):nth-child(-n + 12) {
    display: none;
}
