.main {
    /* margin-bottom: 1rem; */
}

.titleContainer {
    display: flex;
    align-items: center;
}

.subTitle {
    padding-left: 1rem;
    font-size: 1rem;
    font-weight: bold;
}
