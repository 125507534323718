section.scope {
    --color: #263d50;
    --color-border: #dededf;
    --color-edit: #11a2ed;
    --font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    --border-radius: 6px;
    --padding-inline: 22px;

    color: var(--color);
    font-family: var(--font-family);
    border: 1px solid var(--color-border);

    /* by design */
    margin-block: 1rem;
    border-radius: var(--border-radius);

    /* by current environment */
    /* border-radius: 10px; */
    /* margin-block: 24px; */
}

.scope header {
    background-color: unset;
    padding: unset;
    display: flex;
    align-items: center;
    font-weight: 600;
}

.scope header h2,
.scope header button {
    height: 60px;
    padding-inline: var(--padding-inline);
    background-color: white;
    transition: filter 0.15s;
}

.scope header > *:hover {
    filter: brightness(0.96);
}

.scope h2 {
    margin: unset;
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 1.29em;
    cursor: pointer;
    border-top-left-radius: var(--border-radius);
}
.scope header[class~="closed"] h2 {
    border-bottom-left-radius: var(--border-radius);
}
.scope h2 span::after {
    content: "";
    display: inline-block;
    border: 0.33em solid transparent;
    border-left-color: var(--color);
    margin-left: 1em;
    transition: rotate 0.25s;
}
.scope header[class~="opened"] span::after {
    rotate: 90deg;
}

.scope header > *:last-of-type {
    border-top-right-radius: var(--border-radius);
}

.scope button {
    all: unset;
    flex: 0;
    white-space: nowrap;
    color: var(--color-edit);
    font-size: 1.14em;
}
.scope header[class~="closed"] > *:last-of-type {
    border-bottom-right-radius: var(--border-radius);
}

[class~="collapse-box"] {
    border-top: 1px solid var(--color-border);
}

[class~="collapse-box"] > div {
    padding: var(--padding-inline);
}

.scope [class~="collapse-box"] [class~="card"] {
    box-shadow: unset;
}

.scope [class~="collapse-box"] [class~="card-body"] {
    padding: unset;
}
