.textLabel {
    margin-bottom: 1rem;
    font-size: 1rem;
}

.mainTitle {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1rem;
}
