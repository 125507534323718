.AlertLine {
    padding: 1rem;
    background-color: #ffd943;
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .AlertLine:hover {
    cursor: pointer;
} */

.icon {
    height: 2.5rem;
}

.text {
    font-size: 1.2rem;
    color: var(--darkBlue);
    font-family: var(--roboto);
    font-weight: 700;
    margin: 0;
    padding: 0;
    line-height: 2.4rem;
    width: 70%;
    margin-left: 2rem;
}

.actionText {
    font-size: 1.6rem;
    color: var(--darkBlue);
    font-family: var(--segoe);
    font-weight: 700;
}

.actionTextContainer {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.rightIcon {
    width: 3rem !important;
    height: 3rem !important;
}

@media (max-width: 599px) {
    .actionText,
    .icon {
        display: none;
    }

    .text {
        width: 90%;
        font-size: 1rem;
    }
}
