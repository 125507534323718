.dataTables_wrapper {
    .custom-select {
        margin-left: 0 !important;
        width: 25%;
    }
}

.user-table,
.doctor-table {
    p {
        margin: 0;
    }
}

#user-form,
#admin-form,
#package-form,
#profile-form,
#institution-form,
#doctor-admin-form,
.general-form {
    padding: 2rem;

    .btn {
        width: 150px;
    }

    .form-group {
        margin-bottom: 0.5rem;
    }
}

.employers-table,
.doctor-table,
.admin-table {
    .Pic {
        width: 100%;
        max-width: 100px;
        height: auto;
    }
}

.institution-table {
    .institutionPic {
        width: 100%;
        max-width: 100px;
        height: auto;
    }
}

.package-table {
    tr,
    th,
    td {
        padding: 0.5rem;
    }
}

.t-header {
    margin-right: 1rem;
}

.default-info {
    border: 0;
}

.channelListItem {
    margin-bottom: 2rem;
}

.image-preview {
    margin-top: 1rem;
}

.image {
    width: 100%;
    max-width: 200px;
    height: auto;
}

.newChannel {
    border: 1px solid #1fba9c;
    border-top: 1px solid #1fba9c !important;
    border-radius: 5px;
}
