.descriptionContainer {
    display: flex;
}

.descriptionContainer p {
    margin-bottom: 0;
    font-family: var(--roboto);
    font-size: 12px;
    color: #263d50;
    opacity: 0.6;
}

.text {
    font-size: 14;
    font-family: var(--roboto);
    margin-bottom: 15px;
}
