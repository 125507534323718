.title {
    font-size: 1.2rem;
    font-family: var(--roboto);
    font-weight: 700;
    color: #263d50;
}

.content * {
    font-size: 1rem;
    font-family: var(--roboto);
    line-height: 2.2rem;
    color: #263d50ae;
}

.buttonText {
    font-size: 1rem;
    font-weight: 700;
}
