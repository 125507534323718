/* .scope [class~="collapse-box"] p {
    border: 1px solid var(--color-border);
    min-height: 4em;
    padding-inline: 0.5ch;
    margin-bottom: 2em;
} */

.scope [class~="collapse-box"] hr {
    margin-block: 3em 2em;
}
