.ChatMessage {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.messageSent {
    align-items: flex-end;
}

.messageReceived {
    align-items: flex-start;
}

.date {
    font-size: 1rem;
    font-family: var(--roboto);
    color: var(--darkBlue);
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.messageBody {
    max-width: 80%;
    padding: 0.5rem 0.5rem;
    border-radius: 6px;
    font-size: 1rem;
    color: var(--darkBlue);
    font-family: var(--roboto);
}

.messageSent .messageBody {
    background-color: #1fba9b5f;
}

.messageReceived .messageBody {
    background-color: #f8f8f8;
}

.imageMessageBody,
.attachmentMessageBody {
    max-width: 50%;
}

.image {
    max-width: 100%;
    max-height: 10rem;
    object-fit: cover;
}

.image:hover {
    cursor: zoom-in;
}

.modalImage {
    width: 100%;
    object-fit: contain;
}

.attachmentMessageBody {
    display: flex;
    padding-right: 2rem;
    padding-left: 2rem;
}

.attachmentMessageBody:hover {
    cursor: pointer;
}

.attachmentLeft {
    display: flex;
    align-items: center;
    /* width: 70%; */
    padding-right: 2rem;
    border-right: 1px solid var(--grey);
}

.attachmentRight {
    display: flex;
    padding-left: 2rem;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-family: var(--roboto);
    color: var(--darkBlue);
    font-weight: 700;
}

.icon {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
}

.dataContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.fileName {
    font-size: 1.2rem;
    font-weight: 700;
    font-family: var(--roboto);
    color: var(--darkBlue);
    margin-bottom: 1rem;
    word-break: break-all;
    /* flex: 1; */
    /* display: inline-flex; */
}

.name {
    text-overflow: ellipsis;
    width: 90%;
    overflow: hidden;
}

.fileSize {
    font-size: 1.2rem;
    font-family: var(--roboto);
    color: var(--darkBlue);
}

.attachment {
    display: none;
}

@media (max-width: 599px) {
    .imageMessageBody,
    .attachmentMessageBody {
        max-width: 90%;
        padding: 0.5rem;
    }

    .attachmentLeft {
        border-right: none;
    }

    .messageBody {
        font-size: 1rem;
    }

    .attachmentRight {
        display: none;
    }
}
