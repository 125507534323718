.unchecked {
    width: 20px;
    height: 20px;
    border: 1px solid #263d50;
    border-radius: 2px;
    cursor: pointer;
}

.checked {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: var(--green);
    border: 1px solid var(--green);
    border-radius: 2px;
    cursor: pointer;
}
