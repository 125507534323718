.button {
    width: 20rem;
    max-width: 45%;
    height: 3rem;
    background-color: #1fba9c;
    border-radius: 27px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin: 1.5rem 0;
    cursor: pointer;
}

.disabled {
    width: 20rem;
    max-width: 45%;
    height: 3rem;
    background-color: #ccc;
    border-radius: 27px;
    display: flex;
    align-items: center;
    margin: 1.5rem 0;
    pointer-events: none;
    opacity: 0.4;
}

.button span,
.disabled span {
    flex: 1;
    text-align: center;
    font-size: 1.1rem;
    color: #fff;
    font-weight: bold;
    font-family: var(--sagoe);
    /* margin-left: 7rem; */
}

.arrowIcon {
    color: #fff;
    width: 2rem !important;
    height: 2rem !important;
    margin-right: 1rem;
}

@media (max-width: 599px) {
    .button {
        width: 15rem;
        height: 3rem;
        margin: 1.5rem 0;
    }

    .button span {
        font-size: 1rem;
        color: #fff;
        font-weight: bold;
        font-family: var(--sagoe);
        margin-left: 5rem;
    }
}
