.reservationFilter {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* font-size: 160%; */
}

.reservationFilter .muiSelect * {
    font-size: 100% !important;
}

.extraText {
    top: -2rem;
    left: -2.1rem;
    background-color: var(--yellow);
    color: var(--darkBlue);
    font-family: var(--roboto);
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 4rem;
    text-align: center;
}

.extraText span {
    position: relative;
    display: block;
    line-height: 150%;
    padding: 0.5rem 2.5rem;
}

.extraText .triangle {
    width: 1px;
    height: 1px;
    border: 1.5rem solid transparent;
    transform: rotateZ(45deg);
    position: absolute;
    left: 0.6rem;
    bottom: -1.5rem;
    border-bottom-color: #e9c944;
}

.button {
    margin: 0 auto;
}
