.text {
    margin-bottom: 0;
}

.new {
    margin-bottom: 0;
    padding: 0.3rem;
    margin-left: 1rem;
    color: white;
    background-color: #1fba9c;
    font-size: 0.8rem;
    border-radius: 5px;
}

.listItem {
    margin-bottom: 1rem;
}
