.titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.titleLeft span {
    font-size: 20px;
    font-weight: bold;
    font-family: var(--roboto);
    color: #263d50;
}

.titleLeft {
    display: flex;
    align-items: center;
}

.btnWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.commentsWrapper {
    margin-top: 23px;
}
