.main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ced4da;
}

.nameWrapper {
    flex: 4;
    font-size: 14px;
    font-family: var(--roboto);
    color: var(--darkblue);
}

.checkBoxWrapper {
    flex: 1;
    display: flex;
    justify-content: space-between;
}

.deleteWrapper {
    cursor: pointer;
}
