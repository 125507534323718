.container {
    width: 100%;
    background-color: #f2fbf9;
    border: 1px solid #929ea7;
    border-radius: 12px;
    padding: 1rem 1rem;
}

.textarea {
    resize: none;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1rem;
    color: var(--darkBlue);
    font-family: var(--segoe);
}

.label {
    font-size: 1.6rem;
    color: var(--darkBlue);
    font-family: var(--segoe);
    padding-left: 1rem;
    margin-bottom: 1rem;
}

@media (max-width: 599px) {
    .textarea {
        font-size: 1.6rem;
    }
}
