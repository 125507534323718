.header {
    font-size: 26px;
    font-weight: 700;
    text-decoration: underline;
}

.statElement {
    border: 1px solid #ccc;
    border-radius: 12px;
    padding: 10px 30px 10px;
    margin-bottom: 20px;
    margin-top: 10px;
    background-color: #fff;
}

.queryInputs {
    display: flex;
    align-items: center;
}

.queryInputs div {
    display: flex;
    align-items: center;
    margin: 0 10px;
}
