.input {
    background-color: #f1fdfb;
    border: 1px solid #929ea7;
    border-radius: 12px;
    width: 100%;
    max-width: 100%;
    height: 3rem;
    font-family: var(--roboto);
    color: #263d50;
    padding: 0.8rem 1rem;
    font-size: 1rem;
    margin-bottom: 2rem;
}

.input2 {
    flex-grow: 1;
    height: 4rem;
    font-family: var(--roboto);
    color: #263d50;
    /* padding: 1rem 1.2rem; */
    font-size: 1.6rem;
}

.passwordContainer {
    background-color: #f1fdfb;
    border: 1px solid var(--green);
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.visibility {
    padding: 1rem;
}

.visibility:hover {
    cursor: pointer;
}

.passwordContainer .icon {
    color: var(--green);
    font-size: 2.5rem;
}

.input.bigger {
    height: 5rem;
    border-radius: 6px;
    padding: 1.4rem 1.8rem;
    font-size: 1.8rem;
}
