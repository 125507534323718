.titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.titleLeft {
    display: flex;
    align-items: center;
}

.btnWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
