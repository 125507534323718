.notification {
    background-color: #ffd943;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
}

.icon {
    width: 1rem;
    height: 1rem;
}
