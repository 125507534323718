.infoWrapper {
    border-radius: 12px;
    padding: 0 2rem;
    height: 4rem;
}

.textWrapper {
    display: flex;
    align-items: center;
}

.text {
    font-size: 1rem;
    font-family: var(--roboto);
}

/* .dot {
  width: 3.5rem;
  height: 3.5rem;
  background-color: #929EA7;
  border-radius: 50%;
  margin-left: 1rem;
  opacity: 0.5;
} */

.innerWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.imageContainer {
    width: 3rem;
    height: 3rem;
    overflow: hidden;
    border-radius: 50%;
    margin-left: 1rem;
}

.imageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 599px) {
    .text {
        font-size: 1rem;
    }

    .infoWrapper {
        /* border-radius: 0; */
        padding: 0.5rem;
        height: auto;
    }
}
