section.scope {
    --color: #263d50;
    --border-color: #dededf;
    --border-color-img: #707070;
    --dt-color: #919da6;
    --segoe: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

    color: var(--color);
    font-family: var(--segoe);
    border: 1px solid var(--border-color);

    /* by design */
    margin-block: 1rem;
    border-radius: 6px;

    /* by current environment */
    /* border-radius: 10px; */
    /* margin-block: 24px; */
}

.scope figure {
    background-color: unset;
    display: flex;
    align-items: center;
    padding-block: 1.25em;
    column-gap: 0.5em;
    border-bottom: 1px solid var(--border-color);
}

.scope img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid var(--border-color-img);
}

.scope figcaption {
    font-size: 2em;
    line-height: 1.32;
}

.scope dl {
    background-color: unset;
    margin: unset;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-block: 1.25em;
    column-gap: 3em;
}

.scope dl span {
    display: flex;
    column-gap: 0.7em;
}

.scope dt {
    color: var(--dt-color);
    font-weight: normal;
    flex: 0;
    white-space: nowrap;
}

.scope dd {
    margin: unset;
}
