.title {
    font-size: 32px;
    font-family: Roboto, "sans-serif";
    font-weight: 900;
    margin: 0;
}

.idText {
    color: var(--green);
    margin-left: 10px;
}

.btnWrapper {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    margin-top: 10px;
}

.mainRow p {
    font-size: 1rem;
}

.descriptionContainer {
    display: flex;
}

.editIcon {
    cursor: pointer;
    margin-left: 1rem;
}

.deleteContainer {
    display: flex;
    justify-content: flex-end;
}

.label {
    margin: 0;
}
