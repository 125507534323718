.selectRoot {
    margin-bottom: 2rem;
}

.selectRoot .inputRoot {
    border-radius: 6px;
    background-color: #f2fbf9;
}

.inputRoot {
}

.endAdornment {
    /* top: inherit  */
}

.popupIndicator svg {
    width: 2rem;
    height: 2rem;
}

.inputFocused {
}

.notchedOutline {
    /* border-color: var(--green) !important */
}

.popper {
    font-size: 0.9rem !important;
    /* font-size: 1.5rem !important; */
}
