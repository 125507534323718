//
// Color-picker
//

// Colorpicker 2X
.colorpicker-2x .colorpicker-saturation {
    width: 112px;
    height: 200px;
}

.colorpicker-2x .colorpicker-hue,
.colorpicker-2x .colorpicker-alpha {
    width: 30px;
    height: 200px;
}

.colorpicker-2x .colorpicker-color,
.colorpicker-2x .colorpicker-color div {
    height: 30px;
}
