.tickWrapper {
    display: inline-block;
    border-radius: 50%;
    border: 4px solid #1fba9c;
    margin-bottom: 3rem;
}

.tick {
    height: 4.1rem;
    width: 4.8rem;
    margin: 2rem;
}

.text {
    text-align: center;
    margin-bottom: 3rem;
    font-size: 1.8;
    color: #1fba9c;
    font-weight: bold;
    font-family: var(--sagoe);
}

.saveBtnWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btnWrapper {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-between;
}

.charInfo {
    text-align: end;
    padding-top: 1rem;
    color: #929ea7;
    margin-bottom: 1rem;
}

.textLabel {
    font-size: 1rem;
    margin-bottom: 1rem;
}

/* @media (max-width: 599px) {
  .charInfo {
    font-size: 1.4rem;
  }

  .textareaLabel {
    font-size: 1.4rem;
  }

} */
