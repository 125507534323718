.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    /* margin-bottom: 3rem; */
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.infos {
    display: flex;
    align-items: flex-start;
}

.info {
    margin-right: 2rem;
    font-size: 1rem;
}

.title {
    font-size: 1.2rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    line-height: normal;
    text-transform: uppercase;
}

.identifier {
    font-size: 1.2rem;
    font-family: var(--roboto);
    color: var(--darkBlue);
}

.inner {
    width: 100%;
    height: 50vh;
    border: 1px solid var(--grey);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: #fff;
}

.startDummy,
.endDummy {
}

@media (max-width: 599px) {
    .header {
        flex-direction: column;
        align-items: flex-start;
        border-bottom: 1px solid var(--grey);
        padding-bottom: 1rem;
    }

    .title {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    .inner {
        border: none;
        margin-bottom: 1rem;
    }
}
