//
// _footer.scss
//

.footer {
    bottom: 0;
    padding: 20px calc(#{$grid-gutter-width} / 2);
    position: absolute;
    right: 0;
    text-align: center;
    color: $footer-color;
    left: 240px;
    height: $footer-height;
    background-color: $footer-bg;
}

@media (max-width: 992px) {
    .footer {
        left: 0;
    }
}

// Enlarge menu
.vertical-collpsed {
    .footer {
        left: $sidebar-collapsed-width;
    }
}

body[data-layout="horizontal"] {
    .footer {
        left: 0 !important;
    }
}
