.main {
    width: 100%;
    border: 1px solid var(--green);
    border-radius: 3px;
    padding: 8px 15px;
    margin-bottom: 15px;
    cursor: pointer;
    color: var(--green);
    font-size: 14px;
    font-family: var(--roboto);
}
