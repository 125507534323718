.outer {
    width: 30rem;
    max-width: 100%;
    padding: 0.5rem 1rem !important;
    border: 1px solid #929ea7 !important;
    border-radius: 12px;
    background: #f2fbf9;
}

.select {
    border: none;
    background-color: transparent !important;
}

.item {
    font-size: 1rem;
}

.outer ::before {
    border: none !important;
}

.input {
    font-size: 3rem;
}

.label {
    font-size: 1.6rem;
    color: var(--darkBlue);
    font-family: var(--segoe);
    padding-left: 1rem;
    margin-bottom: 1rem;
}

@media (max-width: 599px) {
    .outer {
        padding: 0.8rem 1rem !important;
    }
}
