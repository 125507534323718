.main {
    padding: 15px 0;
    border-top: 1px solid #ced4da;
}

.title {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.textWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 10px;
}

.name {
    font-size: 14px;
    font-family: var(--roboto);
    color: #263d50;
}

.date {
    font-size: 12px;
    font-family: var(--roboto);
    color: #263d50;
    opacity: 0.6;
}

.comment {
    font-size: 14px;
    font-family: var(--roboto);
    color: #263d50;
    opacity: 0.6;
}

.btnWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.icon {
    cursor: pointer;
}
