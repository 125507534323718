.picker {
    width: 100%;
}

.nothingSelectedText {
    text-align: center;
    margin-top: 15rem;
    font-weight: 700;
    font-size: 3rem;
    line-height: normal;
    color: var(--grey);
    font-family: var(--roboto);
}

@media screen and (max-width: 599px) {
    .nothingSelectedText {
        margin-top: -1rem;
        margin-bottom: 2rem;
    }
}
