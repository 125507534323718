.text {
    margin-bottom: 0;
}

.main {
    margin: 1rem;
    padding: 1rem;
    border-radius: 10px;
    /* border: 0.5px solid red; */
}
