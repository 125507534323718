.UploadedFile {
    display: flex;
    background-color: #fff;
    border: 1px solid var(--grey);
    border-radius: 6px;
    width: 30rem;
    /* padding: 1.5rem 2rem 1.5rem 1rem; */
    padding: 0.75rem;
    align-items: center;
}

.UploadedFile:hover {
    cursor: pointer;
}

.icon {
    width: 1.5rem;
    height: 1.5rem;
}

.name {
    font-size: 1rem;
    font-family: var(--roboto);
    font-weight: 700;
    color: var(--darkBlue);
    margin-left: 1rem;
}

.download {
    display: none;
}

.delete {
    margin-left: auto;
}

@media (max-width: 959px) {
    .name {
        font-size: 1rem;
    }
}
