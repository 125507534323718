.Button {
    display: flex;
    padding: 0px 1.7rem;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    width: 25rem;
    max-width: 100%;
    line-height: 3rem;
    background: var(--green);
    border: 1px solid #1b927e;
    font-size: 1rem;
    font-family: "Roboto", sans-serif;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
}

.Button:hover {
    cursor: pointer;
    background: #1b9c82;
}

.rightIcon {
    width: 1.5rem;
    object-fit: contain;
}

.dummy {
    width: 1.5rem;
}

.Button.whiteBorder {
    border-color: #fff;
}

@media (max-width: 1100px) {
    .Button {
        margin-bottom: 1rem;
    }
}
