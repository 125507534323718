.ChatRoom {
    display: flex;
    flex-direction: column;
}

.ChatRoom > * {
    order: 1;
}

.container {
    padding: 5rem 0;
}

.card {
    padding: 7rem 6rem;
}

.textareaContainer {
    width: 95%;
    margin-right: auto;
    margin-left: auto;
    /* margin-top: 2rem; */
    order: 3;
}

.fileContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
    order: 2;
    width: 95%;
    margin-right: auto;
    margin-left: auto;
}

.uploadButtonInner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.uploadIcon {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
}

.buttons {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    order: 4;
}

.button {
    background-color: #fff;
    border-color: var(--grey);
    /* margin-bottom: 1rem; */
    /* margin-bottom: 2rem; */
    /* line-height: 5.8rem; */
}

.button:hover {
    background-color: #fff;
}

.buttonText {
    color: var(--darkBlue);
    font-size: 1rem;
    font-family: var(--segoe);
}

.alertContainer {
    width: 100%;
    background-color: var(--yellow);
    padding: 2.5rem 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    order: 5;
}

.alertIcon {
    width: 4rem;
    height: 4rem;
    margin-right: 2rem;
}

.alertText {
    font-size: 1.5rem;
    font-family: var(--roboto);
    font-weight: 700;
    color: var(--darkBlue);
}

.link {
    text-decoration: underline;
    color: var(--darkBlue);
}

.link:hover {
    cursor: pointer;
}

.otherInfoText {
    color: var(--darkBlue);
    font-size: 1.8rem;
    font-family: var(--roboto);
    line-height: 2.4rem;
    margin-top: 6rem;
}

.fileContainer .uploadedFile {
    /* width: 32%; */
    margin-right: 0.5%;
    margin-left: 0.5%;
    margin-bottom: 2rem;
}

.uploadInput {
    display: none;
}

.uploadFileName {
    font-size: 1.2rem;
    font-family: var(--roboto);
    color: var(--darkBlue);
    margin-left: 2rem;
}

.send {
    color: var(--green);
    text-decoration: underline;
}

.documentMessageContainer {
    display: flex;
    align-items: center;
}

.delete {
    color: #ed1224;
    font-size: 1rem;
    text-decoration: underline;
    margin-left: 4rem;
    font-weight: 700;
}

.delete:hover {
    cursor: pointer;
}

.imageMessage {
    width: 15%;
    object-fit: contain;
}

.iconMessage {
    width: 4rem;
}

.selector-container {
    margin: 0 auto;
}

@media (max-width: 1100px) {
    .fileContainer {
        justify-content: space-between;
    }

    .fileContainer .uploadedFile {
        width: 48%;
        margin-right: 0;
        margin-left: 0;
    }

    .button {
        margin-bottom: 1rem;
    }
}

@media (max-width: 959px) {
    .fileContainer,
    .textareaContainer {
        width: 100%;
    }

    .button,
    .sendButton {
        margin-bottom: 2rem;
    }

    .button,
    .sendButton {
        width: 100%;
    }
}

@media (max-width: 599px) {
    .fileContainer .uploadedFile {
        width: 100%;
    }

    .row {
        display: flex;
    }

    .alertIcon {
        display: none;
    }

    .alertContainer {
        padding: 1rem 1.6rem;
        margin-left: -16px;
        margin-right: -16px;
        width: auto;
    }

    .alertText {
        font-size: 1.4rem;
    }

    .otherInfoText {
        font-size: 1.6rem;
        margin-top: 3rem;
    }

    .fileContainer {
        order: 5;
    }

    .fileContainer .uploadedFile {
        margin-bottom: 1rem;
    }

    .buttons {
        margin-bottom: 1rem;
    }

    /* .buttonText {
        font-size: 0.8rem;
    } */

    .uploadIcon {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
    }
}
