//
// avatar.scss
//

.avatar-xs {
    height: 2.3rem;
    width: 2.3rem;
}

.avatar-sm {
    height: 3rem;
    width: 3rem;
}

.avatar-md {
    height: 4.5rem;
    width: 4.5rem;
}

.avatar-lg {
    height: 5.5rem;
    width: 5.5rem;
}

.avatar-xl {
    height: 7.5rem;
    width: 7.5rem;
}

.avatar-title {
    align-items: center;
    background-color: $primary;
    color: $white;
    display: flex;
    font-weight: $font-weight-medium;
    height: 100%;
    justify-content: center;
    width: 100%;
}
