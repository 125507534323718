div.scope {
    --color-dt: #919da6;
    --gap: 1em;

    display: grid;
    grid-template-columns: repeat(
        auto-fit,
        minmax(20ch, calc(25% - var(--gap)))
    );
    grid-auto-flow: row;
    gap: var(--gap);
}

.scope dl {
    line-height: 1.36;
    margin: unset;
}

.scope dt {
    color: var(--color-dt);
    font-weight: normal;
}

.scope dd {
    margin: unset;
}
